import React, { useState } from 'react';
import {
    Button,
    Field,
    Form,
    Label,
    Popover,
    Section,
} from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { GlyphIcon } from '@theorchard/suite-icons';
import PublisherSearchDropdown from 'src/pages/songWriterEditModal/components/publisherSearchDropdown';
import { CLASSNAME } from 'src/pages/songWriterEditModal/songWriterEditModal';
import type { Publisher } from 'src/data/queries';
import type { AssociatedComposition } from 'src/pages/songWriterEditModal/utils';

interface Props {
    labelUuid: string;
    compositions: AssociatedComposition[];
    selectedCompositionIds: string[];
    selectedRowPublisherName?: string;
}

const EditPublishingCompanies = ({
    labelUuid,
    compositions,
    selectedCompositionIds,
    selectedRowPublisherName,
}: Props) => {
    const resetData = () => {
        setUpdatePublisher(false);
        setSelectedPublisher(undefined);
        setIsDisabled(true);
    };

    const getCompositionsFromSelectedRows = (
        selectedRows: string[],
        compositions: AssociatedComposition[]
    ): AssociatedComposition[] =>
        compositions.filter(composition =>
            selectedRows.find(
                selectedRow =>
                    `${composition.id}/${composition.agreementId}` ===
                    selectedRow
            )
        );

    const [showContent, setShowContent] = useState(false);

    const [selectedPublisher, setSelectedPublisher] = useState<
        Publisher | undefined
    >(undefined);

    const [updatePublisher, setUpdatePublisher] = useState<boolean>(false);

    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    return (
        <Popover
            className={CLASSNAME + '-editPublishingCompany-popover'}
            placement="top"
            id="edit-publishing-company-popover"
            show={showContent}
            content={
                <>
                    <Section>
                        <Section.Header
                            title={formatMessage(
                                'songWriterManager.editPublishingCompaniesForSelectedCompositions'
                            )}
                        />
                        <Section.Body
                            className={
                                CLASSNAME + '-editPublishingCompany-body'
                            }
                        >
                            <div
                                className={
                                    CLASSNAME +
                                    '-editPublishingCompany-body-description'
                                }
                            >
                                {formatMessage(
                                    'songWriterManager.currentPublishingCompaniesInSelection'
                                )}
                                <b>
                                    {selectedRowPublisherName ??
                                        [
                                            ...new Set(
                                                getCompositionsFromSelectedRows(
                                                    selectedCompositionIds,
                                                    compositions
                                                ).map(
                                                    composition =>
                                                        composition.publisherName
                                                )
                                            ),
                                        ].join(',')}
                                </b>
                            </div>
                            <Form validated>
                                <Field
                                    controlId="editPublishingCompany"
                                    labelText={formatMessage(
                                        'songWriterManager.action'
                                    )}
                                >
                                    <Form.Check
                                        name="editPublishingCompany"
                                        label={formatMessage(
                                            'songWriterManager.unassignPublishingCompanies'
                                        )}
                                        type="radio"
                                        onChange={() => {
                                            setUpdatePublisher(false);
                                            setSelectedPublisher(undefined);
                                            setIsDisabled(false);
                                        }}
                                    />
                                    <Form.Check
                                        name="editPublishingCompany"
                                        label={formatMessage(
                                            'songWriterManager.updatePublishingCompanies'
                                        )}
                                        type="radio"
                                        onChange={() => {
                                            setUpdatePublisher(true);
                                            setIsDisabled(true);
                                        }}
                                    />
                                </Field>
                                {updatePublisher && (
                                    <Field
                                        controlId="updatePublishingCompany"
                                        labelText={formatMessage(
                                            'songWriterManager.updatePublishingCompaniesTo'
                                        )}
                                    >
                                        <PublisherSearchDropdown
                                            labelUuid={labelUuid}
                                            onChange={value => {
                                                setSelectedPublisher({
                                                    id: value?.id || '',
                                                    name: value?.name || '',
                                                });
                                                setIsDisabled(false);
                                            }}
                                            publisherId={selectedPublisher?.id}
                                        />
                                        <Label
                                            className={
                                                CLASSNAME +
                                                '-editPublishingCompany-body-description-update-label'
                                            }
                                            text={formatMessage(
                                                'songWriterManager.updatePublishingCompaniesMessage'
                                            )}
                                        />
                                    </Field>
                                )}
                                <Button
                                    variant="primary"
                                    disabled={isDisabled}
                                    className="btn-save"
                                    onClick={() => setShowContent(false)}
                                >
                                    {formatMessage('generic.save')}
                                </Button>
                                <Button
                                    variant="tertiary"
                                    className="btn-cancel"
                                    onClick={() => setShowContent(false)}
                                >
                                    {formatMessage('generic.cancel')}
                                </Button>
                            </Form>
                        </Section.Body>
                    </Section>
                </>
            }
        >
            <div
                onClick={() => {
                    resetData();
                    setShowContent(!showContent);
                }}
            >
                <GlyphIcon name="edit" size={16} />
            </div>
        </Popover>
    );
};

export default EditPublishingCompanies;
