import React, { type FC } from 'react';
import { FullscreenModal, Button, Pill } from '@theorchard/suite-components';
import {
    BRAND_AWAL,
    formatMessage,
    useIdentity,
} from '@theorchard/suite-frontend';
import { CLASSNAME } from '../../songWriterEditModal';
import type { Songwriter, WriterAgreement } from 'src/types';

interface Props {
    songwriterData: Songwriter;
    saveDisabled: boolean;
    saveModalCallback: () => void;
    closeModalCallback: () => void;
    controlledAgreements: WriterAgreement[];
    nonControlledAgreements: WriterAgreement[];
}

const SongWriterModalHeader: FC<Props> = ({
    songwriterData,
    saveDisabled,
    saveModalCallback,
    closeModalCallback,
    controlledAgreements,
    nonControlledAgreements,
}) => {
    const { defaultBrand } = useIdentity();

    const uniquePublishers = [
        ...new Set(songwriterData.agreements.map(a => a.publisher?.name)),
    ]
        .filter(p => p)
        .join(', ');

    return (
        <div className={CLASSNAME}>
            <FullscreenModal.Header>
                <div>
                    <Button
                        onClick={() => closeModalCallback()}
                        variant="tertiary"
                        id="cancel-button"
                    >
                        {formatMessage('generic.cancel')}
                    </Button>
                    <Button
                        onClick={saveModalCallback}
                        variant="primary"
                        disabled={saveDisabled}
                    >
                        {formatMessage('generic.save')}
                    </Button>
                </div>
            </FullscreenModal.Header>
            <div className={`${CLASSNAME}-headerContent`}>
                <Pill
                    brand={defaultBrand === BRAND_AWAL ? 'awal' : 'orchard'}
                    text={songwriterData.vendor.name}
                    popoverOptions={{
                        id: 'songwriter-edit-modal-vendor',
                        content: (
                            // our vendors don't have subaccountIds so we don't need to surface subaccount metadata
                            <div className="paddedContainerVariant">
                                <p>
                                    <strong>
                                        {songwriterData.vendor.name}
                                    </strong>{' '}
                                    • {songwriterData.vendor.id.vendorId}
                                </p>
                                <span>{formatMessage('generic.account')}</span>
                            </div>
                        ),
                    }}
                />
                <strong>PRO: &nbsp; </strong> {songwriterData.pro} &nbsp; |
                &nbsp; <strong>IPI: &nbsp; </strong> {songwriterData.ipi} &nbsp;
                | &nbsp;{' '}
                <strong>{`${formatMessage('generic.publishingCompanySelect')}:`}</strong>{' '}
                &nbsp; {uniquePublishers}
            </div>
            <div className={`${CLASSNAME}-title`}>
                <FullscreenModal.Title
                    title={songwriterData.legalName}
                ></FullscreenModal.Title>
                <div className={`${CLASSNAME}-compCount`}>
                    <div id="controlled">
                        <div>{formatMessage('generic.controlled')}</div>
                        <div id="count">
                            {
                                controlledAgreements.flatMap(
                                    a => a.compositions
                                ).length
                            }
                        </div>
                    </div>
                    <div>
                        <div>{formatMessage('generic.nonControlled')}</div>
                        <div id="count">
                            {
                                nonControlledAgreements.flatMap(
                                    a => a.compositions
                                ).length
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SongWriterModalHeader;
