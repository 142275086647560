import { cloneDeep, differenceWith, isEqual } from 'lodash';
import { type WriterAgreement } from 'src/types';
import type { CompositionAgreementPublisherInput } from 'src/data/schemaTypes';

export interface AssociatedComposition {
    id: string;
    title: string;
    publisherName: string | null;
    publisherId: string | null;
    agreementId: string;
}

export const formatAssociatedComps = (
    agreements: WriterAgreement[]
): AssociatedComposition[] =>
    agreements
        .map(a => {
            if (!a.compositions.length) return null;
            return a.compositions.map(c => ({
                id: c.id,
                title: c.title,
                publisherName: a.publisher?.name ?? null,
                publisherId: a.publisher?.id ?? null,
                agreementId: a.id,
            }));
        })
        .filter(c => c !== null)
        .flatMap(c => c)
        .sort((a, b) =>
            a.title.toLowerCase().localeCompare(b.title.toLowerCase())
        );

export const findAndCopyAgreements = (
    compositions: { id: string; agreementId: string }[],
    previous: WriterAgreement[],
    copyFrom: WriterAgreement[]
) => {
    const copy = cloneDeep(previous);

    compositions.forEach(({ id, agreementId }) => {
        const found = copy.find(i => i.id === agreementId);
        const foundCopyFrom = copyFrom.find(i => i.id === agreementId);
        if (found)
            found.compositions = [
                ...(found.compositions ?? []),
                ...(foundCopyFrom?.compositions.filter(i => i.id === id) ?? []),
            ];
        else if (foundCopyFrom)
            copy.push({
                ...foundCopyFrom,
                compositions: foundCopyFrom?.compositions.filter(
                    i => i.id === id
                ),
            });
    });

    return copy;
};

export const removeAgreements = (
    compositions: { id: string; agreementId: string }[],
    previous: WriterAgreement[]
) => {
    const copy = cloneDeep(previous);

    compositions.forEach(({ id, agreementId }) => {
        const found = copy.find(i => i.id === agreementId);

        if (found)
            found.compositions = found.compositions.filter(i => i.id !== id);
    });

    return copy;
};

export const mapSelectedAgreement = (agreement: string) => {
    const [id, agreementId] = agreement.split('/');
    return { id, agreementId };
};

export const changedCompositions = (
    controlledBefore: WriterAgreement[],
    controlledAfter: WriterAgreement[]
): CompositionAgreementPublisherInput[] => {
    const beforeCompositions = controlledBefore.flatMap(i =>
        i.compositions.map(c => ({
            agreementUuid: i.id,
            compositionUuid: c.id,
            controlled: i.controlled,
            publisherUuid: i.publisher?.id,
        }))
    );

    const afterCompositions = controlledAfter.flatMap(i =>
        i.compositions.map(c => ({
            agreementUuid: i.id,
            compositionUuid: c.id,
            controlled: i.controlled,
            publisherUuid: i.publisher?.id,
        }))
    );

    const changes = [
        ...differenceWith(beforeCompositions, afterCompositions, isEqual),
        ...differenceWith(afterCompositions, beforeCompositions, isEqual),
    ].map(i => ({ ...i, controlled: !i.controlled }));

    return changes;
};
