import React, { useEffect, useState, type FC } from 'react';
import { Section, GridTable, useToast } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { GlyphIcon, Illustration } from '@theorchard/suite-icons';
import { type UpdatePublishingSongWriterMetaDataInput } from 'src/data/schemaTypes';
import EditPublishingCompanies from 'src/pages/songWriterEditModal/components/associatedCompositions/editPublishingCompanies';
import {
    type AssociatedComposition,
    formatAssociatedComps,
    findAndCopyAgreements,
    removeAgreements,
    mapSelectedAgreement,
} from '../../utils';
import PublisherSearchDropdown from '../publisherSearchDropdown';
import type { Publisher } from 'src/data/queries';
import type { WriterAgreement } from 'src/types';

interface Props {
    controlledAgreements: WriterAgreement[];
    setControlledAgreements: React.Dispatch<
        React.SetStateAction<WriterAgreement[]>
    >;
    nonControlledAgreements: WriterAgreement[];
    setNonControlledAgreements: React.Dispatch<
        React.SetStateAction<WriterAgreement[]>
    >;
    labelUuid: string;
    updatedWriter: UpdatePublishingSongWriterMetaDataInput;
    setHasMovedCompositionToControlled: React.Dispatch<
        React.SetStateAction<boolean>
    >;
}

export const SECTION_CLASSNAME = 'SongWriterEditModal-associatedComps';

const AssociatedCompositions: FC<Props> = ({
    controlledAgreements,
    setControlledAgreements,
    nonControlledAgreements,
    setNonControlledAgreements,
    labelUuid,
    updatedWriter,
    setHasMovedCompositionToControlled,
}) => {
    const [controlledComps, setControlledComps] = useState(
        formatAssociatedComps(controlledAgreements)
    );
    const [nonControlledComps, setNonControlledComps] = useState(
        formatAssociatedComps(nonControlledAgreements)
    );
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [selectedControlledRows, setSelectedControlledRows] = useState<
        string[]
    >([]);
    const [selectedNonControlledRows, setSelectedNonControlledRows] = useState<
        string[]
    >([]);
    const [selectedPublisher, setSelectedPublisher] = useState<
        Publisher | undefined
    >(undefined);

    const moveAgreements = (
        isControlled: boolean,
        agreement?: AssociatedComposition
    ) => {
        if (isControlled) {
            setNonControlledAgreements(previous =>
                findAndCopyAgreements(
                    agreement
                        ? [agreement]
                        : selectedControlledRows.map(mapSelectedAgreement),
                    previous,
                    controlledAgreements
                )
            );
            setControlledAgreements(previous =>
                removeAgreements(
                    agreement
                        ? [agreement]
                        : selectedControlledRows.map(mapSelectedAgreement),
                    previous
                )
            );
            setSelectedControlledRows(prev =>
                agreement
                    ? prev.filter(
                          i => i !== `${agreement.id}/${agreement.agreementId}`
                      )
                    : []
            );
        } else {
            setControlledAgreements(previous =>
                findAndCopyAgreements(
                    agreement
                        ? [agreement]
                        : selectedNonControlledRows.map(mapSelectedAgreement),
                    previous,
                    nonControlledAgreements
                )
            );
            setNonControlledAgreements(previous =>
                removeAgreements(
                    agreement
                        ? [agreement]
                        : selectedNonControlledRows.map(mapSelectedAgreement),
                    previous
                )
            );
            setSelectedNonControlledRows(prev =>
                agreement
                    ? prev.filter(
                          i => i !== `${agreement.id}/${agreement.agreementId}`
                      )
                    : []
            );
            setHasMovedCompositionToControlled(true);
        }

        toast(
            formatMessage('songWriterManager.compsMoved', {
                number: agreement
                    ? 1
                    : isControlled
                      ? selectedControlledRows.length
                      : selectedNonControlledRows.length,
                controlledStatus: isControlled // column where moved
                    ? 'Non-Controlled'
                    : 'Controlled',
            })
        );
    };

    const handleSort = (
        compositions: AssociatedComposition[],
        setCompositions: React.Dispatch<
            React.SetStateAction<AssociatedComposition[]>
        >
    ) => {
        const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        setSortDirection(newSortDirection);

        const sortedComps = [...compositions].sort((a, b) => {
            if (newSortDirection === 'asc') {
                return a.title.localeCompare(b.title);
            } else {
                return b.title.localeCompare(a.title);
            }
        });

        setCompositions(sortedComps);
    };

    const toast = useToast();

    useEffect(() => {
        if (selectedPublisher) {
            const filteredControlledComps = formatAssociatedComps(
                controlledAgreements.filter(agreement =>
                    selectedPublisher.id === '0'
                        ? !agreement.publisher
                        : agreement.publisher?.id === selectedPublisher.id
                )
            );

            const filteredNonControlledComps = formatAssociatedComps(
                nonControlledAgreements.filter(agreement =>
                    selectedPublisher.id === '0'
                        ? !agreement.publisher
                        : agreement.publisher?.id === selectedPublisher.id
                )
            );

            setControlledComps(filteredControlledComps);
            setNonControlledComps(filteredNonControlledComps);
        }

        if (selectedPublisher === undefined) {
            setControlledComps(formatAssociatedComps(controlledAgreements));
            setNonControlledComps(
                formatAssociatedComps(nonControlledAgreements)
            );
        }
    }, [selectedPublisher, controlledAgreements, nonControlledAgreements]);

    return (
        <div className={SECTION_CLASSNAME}>
            <div className={`${SECTION_CLASSNAME}-header`}>
                <h2>{formatMessage('songWriters.associatedCompositions')}</h2>
                <PublisherSearchDropdown
                    labelUuid={labelUuid}
                    onChange={value =>
                        setSelectedPublisher({
                            id: value?.id || '',
                            name: value?.name || '',
                        })
                    }
                    publisherId={selectedPublisher?.id}
                />
            </div>
            <div className={`${SECTION_CLASSNAME}-section`}>
                <div className={`${SECTION_CLASSNAME}-controlled`}>
                    <Section>
                        <Section.Header
                            className={`${SECTION_CLASSNAME}-sectionHeader`}
                            title={formatMessage('generic.controlled')}
                        />
                        <Section.Body>
                            <GridTable
                                variant="zebra"
                                rowKey={data =>
                                    `${data.id}/${data.agreementId}`
                                }
                                className={`${SECTION_CLASSNAME}-controlledComps`}
                                data={controlledComps}
                                selectable
                                stickyHeader
                                testId="controlled-compositions"
                                emptyStateComponent={
                                    <div
                                        className={`${SECTION_CLASSNAME}-emptyState`}
                                    >
                                        {!!selectedPublisher &&
                                        !controlledComps.length ? (
                                            <Illustration
                                                name="noResults"
                                                size={100}
                                            />
                                        ) : (
                                            <Illustration
                                                name="emptyState"
                                                size={100}
                                            />
                                        )}
                                        <h4>
                                            {!!selectedPublisher &&
                                            !controlledComps.length
                                                ? formatMessage(
                                                      'songWriterManager.noItems'
                                                  )
                                                : formatMessage(
                                                      'songWriterManager.noCompositions',
                                                      {
                                                          controlledStatus:
                                                              'Controlled',
                                                      }
                                                  )}
                                        </h4>
                                        <div>
                                            {!!selectedPublisher &&
                                            !controlledComps.length
                                                ? formatMessage(
                                                      'songWriterManager.resetFilters'
                                                  )
                                                : null}
                                        </div>
                                    </div>
                                }
                                sortBy={{
                                    key: 'title',
                                    direction: sortDirection,
                                }}
                                onSort={() =>
                                    handleSort(
                                        controlledComps,
                                        setControlledComps
                                    )
                                }
                                columnDefs={[
                                    {
                                        name: 'title',
                                        title: `${formatMessage('songWriterManager.compositions')}`,
                                        sortable: true,
                                        sortKey: 'title',
                                        Cell: ({ data }) => (
                                            <div>
                                                <div>{data.title}</div>
                                                {data.publisherName && (
                                                    <div id="publisher">
                                                        {data.publisherName}
                                                    </div>
                                                )}
                                            </div>
                                        ),
                                    },
                                ]}
                                onSelectedRowsChanged={
                                    setSelectedControlledRows
                                }
                                selectedRowKeys={selectedControlledRows}
                                rowActionsHeader=""
                                rowActions={{
                                    edit: {
                                        renderIcon: row => (
                                            <EditPublishingCompanies
                                                labelUuid={labelUuid}
                                                compositions={controlledComps}
                                                selectedCompositionIds={
                                                    selectedControlledRows
                                                }
                                                selectedRowPublisherName={
                                                    row.publisherName || ''
                                                }
                                            />
                                        ),
                                        tooltip: formatMessage(
                                            'songWriterManager.editPublishingCo'
                                        ),
                                        disabled: () =>
                                            !controlledAgreements.length &&
                                            (updatedWriter.pro === '' ||
                                                updatedWriter.ipi === ''),
                                    },
                                    move: {
                                        icon: (
                                            <GlyphIcon
                                                name="arrowRight"
                                                size={16}
                                            />
                                        ),
                                        onClick: agreement =>
                                            moveAgreements(true, agreement),
                                        tooltip: formatMessage(
                                            'songWriterManager.moveComp',
                                            {
                                                controlledStatus:
                                                    'Non-Controlled',
                                            }
                                        ),
                                        disabled: () =>
                                            !controlledAgreements.length &&
                                            (updatedWriter.pro === '' ||
                                                updatedWriter.ipi === ''),
                                    },
                                }}
                                rowBulkActions={{
                                    edit: {
                                        icon: (
                                            <EditPublishingCompanies
                                                labelUuid={labelUuid}
                                                compositions={controlledComps}
                                                selectedCompositionIds={
                                                    selectedControlledRows
                                                }
                                            />
                                        ),
                                        tooltip: formatMessage(
                                            'songWriterManager.editPublishingCo'
                                        ),
                                        onClick: () => {
                                            console.log('bulk actions');
                                        }, // temporary until onClick handled in future ticket
                                        disabled:
                                            !controlledAgreements.length &&
                                            (updatedWriter.pro === '' ||
                                                updatedWriter.ipi === ''),
                                    },
                                    move: {
                                        icon: (
                                            <GlyphIcon
                                                name="arrowRight"
                                                size={16}
                                            />
                                        ),
                                        onClick: () => moveAgreements(true),
                                        tooltip: formatMessage(
                                            'songWriterManager.moveSelectedComps',
                                            {
                                                controlledStatus:
                                                    'Non-Controlled',
                                            }
                                        ),
                                        disabled:
                                            !controlledAgreements.length &&
                                            (updatedWriter.pro === '' ||
                                                updatedWriter.ipi === ''),
                                    },
                                }}
                            />
                        </Section.Body>
                    </Section>
                </div>
                <div className={`${SECTION_CLASSNAME}-nonControlled`}>
                    <Section>
                        <Section.Header
                            className={`${SECTION_CLASSNAME}-sectionHeader`}
                            title={formatMessage('generic.nonControlled')}
                        />
                        <Section.Body>
                            <GridTable
                                variant="zebra"
                                rowKey={data =>
                                    `${data.id}/${data.agreementId}`
                                }
                                className={`${SECTION_CLASSNAME}-nonControlledComps`}
                                selectable
                                data={nonControlledComps}
                                stickyHeader
                                testId="non-controlled-compositions"
                                emptyStateComponent={
                                    <div
                                        className={`${SECTION_CLASSNAME}-emptyState`}
                                    >
                                        {!!selectedPublisher &&
                                        !nonControlledComps.length ? (
                                            <Illustration
                                                name="noResults"
                                                size={100}
                                            />
                                        ) : (
                                            <Illustration
                                                name="emptyState"
                                                size={100}
                                            />
                                        )}
                                        <h4>
                                            {!!selectedPublisher &&
                                            !nonControlledComps.length
                                                ? formatMessage(
                                                      'songWriterManager.noItems'
                                                  )
                                                : formatMessage(
                                                      'songWriterManager.noCompositions',
                                                      {
                                                          controlledStatus:
                                                              'Non-Controlled',
                                                      }
                                                  )}
                                        </h4>
                                        <div>
                                            {!!selectedPublisher &&
                                            !nonControlledComps.length
                                                ? formatMessage(
                                                      'songWriterManager.resetFilters'
                                                  )
                                                : null}
                                        </div>
                                    </div>
                                }
                                sortBy={{
                                    key: 'title',
                                    direction: sortDirection,
                                }}
                                onSort={() =>
                                    handleSort(
                                        nonControlledComps,
                                        setNonControlledComps
                                    )
                                }
                                onSelectedRowsChanged={
                                    setSelectedNonControlledRows
                                }
                                selectedRowKeys={selectedNonControlledRows}
                                rowActionsHeader=""
                                rowActions={{
                                    edit: {
                                        renderIcon: row => (
                                            <EditPublishingCompanies
                                                labelUuid={labelUuid}
                                                compositions={
                                                    nonControlledComps
                                                }
                                                selectedCompositionIds={
                                                    selectedNonControlledRows
                                                }
                                                selectedRowPublisherName={
                                                    row.publisherName || ''
                                                }
                                            />
                                        ),
                                        tooltip: formatMessage(
                                            'songWriterManager.editPublishingCo'
                                        ),
                                        disabled: () =>
                                            !controlledAgreements.length &&
                                            (updatedWriter.pro === '' ||
                                                updatedWriter.ipi === ''),
                                    },
                                    move: {
                                        icon: (
                                            <GlyphIcon
                                                name="arrowLeft"
                                                size={16}
                                            />
                                        ),
                                        onClick: agreement =>
                                            moveAgreements(false, agreement),
                                        tooltip: formatMessage(
                                            'songWriterManager.moveComp',
                                            { controlledStatus: 'Controlled' }
                                        ),
                                        disabled: () =>
                                            !controlledAgreements.length &&
                                            (updatedWriter.pro === '' ||
                                                updatedWriter.ipi === ''),
                                    },
                                }}
                                rowBulkActions={{
                                    edit: {
                                        icon: (
                                            <EditPublishingCompanies
                                                labelUuid={labelUuid}
                                                compositions={
                                                    nonControlledComps
                                                }
                                                selectedCompositionIds={
                                                    selectedNonControlledRows
                                                }
                                            />
                                        ),
                                        tooltip: formatMessage(
                                            'songWriterManager.editPublishingCo'
                                        ),
                                        onClick: () => {
                                            console.log('bulk actions');
                                        }, // temporary until onClick handled in future ticket
                                        disabled:
                                            !controlledAgreements.length &&
                                            (updatedWriter.pro === '' ||
                                                updatedWriter.ipi === ''),
                                    },
                                    move: {
                                        icon: (
                                            <GlyphIcon
                                                name="arrowLeft"
                                                size={16}
                                            />
                                        ),
                                        onClick: () => moveAgreements(false),
                                        tooltip: formatMessage(
                                            'songWriterManager.moveSelectedComps',
                                            { controlledStatus: 'Controlled' }
                                        ),
                                        disabled:
                                            !controlledAgreements.length &&
                                            (updatedWriter.pro === '' ||
                                                updatedWriter.ipi === ''),
                                    },
                                }}
                                columnDefs={[
                                    {
                                        name: 'title',
                                        title: `${formatMessage('songWriterManager.compositions')}`,
                                        sortable: true,
                                        sortKey: 'title',
                                        Cell: ({ data }) => (
                                            <div>
                                                <div>{data.title}</div>
                                                {data.publisherName && (
                                                    <div id="publisher">
                                                        {data.publisherName}
                                                    </div>
                                                )}
                                            </div>
                                        ),
                                    },
                                ]}
                            />
                        </Section.Body>
                    </Section>
                </div>
            </div>
        </div>
    );
};

export default AssociatedCompositions;
