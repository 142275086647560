/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import * as Types from 'src/data/schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type UpdatePublishingSongWriterAgreementsMutationVariables =
    Types.Exact<{
        input: Types.UpdateSongWriterAgreementsInput;
    }>;

export type UpdatePublishingSongWriterAgreementsMutation = {
    __typename?: 'Mutation';
    updatePublishingSongWriterAgreements: {
        __typename?: 'PublishingSongWriter';
        id: string;
    };
};

export const UpdatePublishingSongWriterAgreementsDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: {
                kind: 'Name',
                value: 'UpdatePublishingSongWriterAgreements',
            },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'UpdateSongWriterAgreementsInput',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'updatePublishingSongWriterAgreements',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdatePublishingSongWriterAgreementsMutation,
    UpdatePublishingSongWriterAgreementsMutationVariables
>;
