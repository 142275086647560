import React, { Fragment, useCallback, useState } from 'react';
import {
    Pill,
    TruncatedText,
    useFullscreenModal,
} from '@theorchard/suite-components';
import { formatMessage, useIdentity } from '@theorchard/suite-frontend';
import { GlyphIcon } from '@theorchard/suite-icons';
import { groupBy, uniqWith, isEqual } from 'lodash';
import { useHistory } from 'react-router-dom';
import { FEATURE_FLAGS } from 'src/constants';
import NewSongwriterActions from 'src/pages/songWriterListPage/newSongwriterActions';
import SongWriterDropdown from 'src/pages/songWriterListPage/songWriterDropdown';
import { CLASSNAME } from 'src/pages/songWriterListPage/songWriters';
import { songUrl } from 'src/utils/urls';
import type { SongWriter } from 'src/types';

interface Props {
    category: string;
    songWriter: SongWriter;
    onSongWriterView: (songWriter: SongWriter) => void;
    onSongWriterEdit: (songWriter: SongWriter) => void;
    onSongWriterDelete: (songWriter: SongWriter) => void;
    fromPage: number;
}

type History = ReturnType<typeof useHistory>;
type AgreementCompositions = SongWriter['agreements'][0]['compositions'];

const renderNotRegisteredCell = () => (
    <div className={`${CLASSNAME}-not-registered`}>
        <span>{formatMessage('generic.notRegistered')}</span>
        <GlyphIcon name="warning" size={16} />
    </div>
);

const CompositionList: React.FC<{
    compositions?: AgreementCompositions;
    history: History;
}> = ({ compositions, history }) => (
    <div className={`${CLASSNAME}-link-list`}>
        {compositions?.map((composition, index) => (
            <Fragment key={composition.id}>
                <span
                    className={`${CLASSNAME}-link`}
                    onClick={() => history.push(songUrl(composition.id))}
                >
                    {composition.title}
                </span>
                {index !== compositions.length - 1 && <hr />}
            </Fragment>
        ))}
    </div>
);

const renderAssociatedCompositionsCell = (
    { id, compositions, agreements }: SongWriter,
    history: History
) => {
    const uniqueControlledComposition = uniqWith(
        compositions
            .map(composition =>
                agreements.map(agreement => {
                    if (
                        agreement.compositions.find(
                            c => c.id === composition.id
                        )
                    )
                        return {
                            id: composition.id,
                            title: composition.title,
                            controlled: agreement.controlled,
                            agreementId: agreement.id,
                        };
                })
            )
            .flat()
            .filter(i => i),
        isEqual
    );

    const grouped = groupBy(uniqueControlledComposition, 'controlled');

    const map: Map<string, AgreementCompositions> = Object.keys(grouped).reduce(
        (map, key) => {
            map.set(key, grouped[key]);
            return map;
        },
        new Map()
    );

    if (!map.get('true')?.length && !map.get('false')?.length)
        return <div className={`${CLASSNAME}-pill-zero`}>0</div>;

    return (
        <>
            {map.get('true') && (
                <Pill
                    className={`${CLASSNAME}-pill`}
                    text={`${map.get('true')?.length} ${formatMessage('generic.controlled')}`}
                    popoverOptions={{
                        id: `${id}-controlled`,
                        content: (
                            <CompositionList
                                compositions={map.get('true')}
                                history={history}
                            />
                        ),
                    }}
                />
            )}

            {map.get('false') && (
                <Pill
                    className={`${CLASSNAME}-pill`}
                    text={`${map.get('false')?.length} ${formatMessage('generic.nonControlled')}`}
                    popoverOptions={{
                        id: `${id}-non-controlled`,
                        content: (
                            <CompositionList
                                compositions={map.get('false')}
                                history={history}
                            />
                        ),
                    }}
                />
            )}
        </>
    );
};

const SongWriterRow: React.FC<Props> = ({
    category,
    songWriter,
    onSongWriterView,
    onSongWriterEdit,
    onSongWriterDelete,
    fromPage,
}) => {
    const { open: openFullScreenModal, close: closeFullScreenModal } =
        useFullscreenModal();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const canViewNewSongwriterActions =
        useIdentity().features[FEATURE_FLAGS.USE_NEW_SONGWRITER_ACTIONS];
    const onView = useCallback(
        () => onSongWriterView(songWriter),
        [songWriter, onSongWriterView]
    );
    const history = useHistory();

    const toggleFullscreenModal = () => {
        if (isModalOpen) {
            closeFullScreenModal(() => {
                setIsModalOpen(false);
            });
        } else {
            openFullScreenModal(() => {
                setIsModalOpen(true);
                history.push({
                    pathname: `/song-writer/${songWriter.id}`,
                    state: { songWriter, fromPage },
                });
            });
        }
    };

    return (
        <tr key={songWriter.id}>
            <td className={`${CLASSNAME}-legal-name-column`}>
                <div className="Songs-link" onClick={onView}>
                    {songWriter.legalName}
                </div>
                <TruncatedText
                    className={`${CLASSNAME}-pka`}
                    text={songWriter.professionallyKnownAs.join(', ')}
                    maxWidth={280}
                />
            </td>
            <td>{songWriter.pro || renderNotRegisteredCell()}</td>
            <td>{songWriter.ipi || renderNotRegisteredCell()}</td>
            <td>{renderAssociatedCompositionsCell(songWriter, history)}</td>
            <td>
                {!canViewNewSongwriterActions && (
                    <SongWriterDropdown
                        category={category}
                        songWriter={songWriter}
                        onSongWriterView={onSongWriterView}
                        onSongWriterEdit={onSongWriterEdit}
                        onSongWriterDelete={onSongWriterDelete}
                    />
                )}
                {canViewNewSongwriterActions && (
                    <NewSongwriterActions
                        songWriter={songWriter}
                        onSongWriterEdit={toggleFullscreenModal}
                        onSongWriterDelete={onSongWriterDelete}
                    />
                )}
            </td>
        </tr>
    );
};

export default SongWriterRow;
